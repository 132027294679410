<template>
  <div class="page-wrapper chiller-theme" style="margin-top: 70px">
    <main class="page-content">
      <div class="container-fluid">
        <ul
          class="breadcrumb ProximaNovaRegular"
          style="margin-left: 52px; margin-top: 12px"
        >
          <li>
            <span>Solutions</span>
          </li>
          <li>
            <span>Attribution</span>
          </li>
          <li>
            <span>Data Source</span>
          </li>
          <li class="ProximaNovaBold">KPI</li>
        </ul>
      </div>
       <solution-modal ref="solutionModal" @progressDone="doneProgress" />
      <div class="w-100 py-4">
        <div
          style="margin-top: 36px; padding: 0 52px"
        >
            <div class="container-fluid card">
              <div class="row" style="margin-top: 125px; margin-bottom: 70px">
                <div class="col-md-4 colo-lg-4">
                  <div class="w-100 img-section">
                    <img
                      class="img img-responsive"
                      src="@/assets/Icons/group-21.png"
                      alt="KPI"
                      height="100%"
                    />
                  </div>
                </div>
                <div class="col-md-8 col-lg-8">
                    <div
                      class="w-100 d-flex px-4 mb-4 mt-5"
                    >
                      <span class="ProximaNovaBold" style="font-size: x-large"
                        >Define KPI</span
                      >
                    </div>
                  <div class="w-100 row">
                    <div
                      class="col-md-6 colo-lg-6 d-flex justify-center"
                    >
                      <div class="w-100 p-4 pr-5">
                        <w-dropdown
                          :options="kpidropdown"
                          :placeHolder="'Select'"
                          :labelText="'KPI'"
                          class="mr-3"
                          @input="selectedKPi($event)"
                        ></w-dropdown>
                      </div>
                    </div>
                    <div
                      class="col-md-6 colo-lg-6 d-flex justify-center"
                    >
                      <div class="w-100 p-4 pr-5">
                        <w-dropdown
                          :options="marketDropdown"
                          :labelText="'Market Type'"
                          class="mr-5"
                          @input="selectMarket($event)"
                        ></w-dropdown>
                      </div>
                    </div>
                            <div
                      class="col-md-6 colo-lg-6 d-flex justify-center"
                    >
                      <div class="w-100 p-4 pr-5">
                        <w-dropdown
                          :options="kpidropdown"
                          :placeHolder="'Select'"
                          :labelText="'Analytics'"
                          class="mr-3"
                          @input="selectedKPi($event)"
                        ></w-dropdown>
                      </div>
                    </div>
                         <div
                      class="col-md-6 colo-lg-6 d-flex justify-center"
                    >
                      <div class="w-100 p-4 pr-5">
                        <w-dropdown
                          :options="marketDropdown"
                          :labelText="'Market Type'"
                          class="mr-5"
                          @input="selectMarket($event)"
                        ></w-dropdown>
                      </div>
                    </div>
                    <div class="w-100 pr-5 my-4" >
                      <div
                        class="d-flex justify-content-end mr-2 pr-2"
                        :class="{ changeColor: isDisable }"
                      >
                        <w-button
                          :buttonLoader="'normal'"
                          :buttonText="'Create Plan'"
                          :class="{ resetPasswordLink: isDisable }"
                          :isDisable="isDisable"
                          @buttonClicked="createPlan"
                        >
                        </w-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import Button from "@/components/Profile/Button.vue";
import Dropdown from "@/widgets/Dropdown.vue";
import SolutionModal from "@/components/Modal/SolutionModal.vue";

export default {
  components: {
    "w-button": Button,
    "w-dropdown": Dropdown,
    SolutionModal
  },
  computed: {
    isDisable() {
      if (
        this.selectedMarket !== "Select" &&
        this.selectedKPI !== "Select" &&
        this.selectedMarket !== "" &&
        this.selectedKPI !== ""
      ) {
        return false;
      }
      return true;
    },
  },
  data() {
    return {
      kpidropdown: [
        { text: "Sales", id: "sales" },
        { text: "Leads", id: "leads", disable: false },
        { text: "Profit Margin", id: "Profit Margin", disable: false },
        { text: "Volume", id: "volume", disable: false },
        { text: "Cart Value", id: "Cart Value", disable: false },
      ],
      marketDropdown: [
        { text: "All India", id: "allindia" },
        { text: "USA", id: "USA" },
        { text: "HSM", id: "hsm", disable: false },
        { text: "KER", id: "ker", disable: false },
        { text: "KA", id: "KA", disable: false },
      ],
      selectedMarket: "",
      selectedKPI: "",
    };
  },
  methods: {
    selectedKPi(e) {
      if (this.selectedKPI != e) {
        this.selectedKPI = e;
        console.log(e);
      }
    },
    selectMarket(e) {
      if (e === this.selectedMarket || e === "Select") {
        return;
      }
      sessionStorage.setItem("kpiMarket", JSON.stringify(e));
      this.selectedMarket = e;
    },
       createPlan() {
      this.$refs.solutionModal.open();
    },
    doneProgress() {
      this.$router.push("/solutions/agile-attribution/results");
    },
  },
};
</script>

<style scoped>
.img-section {
  padding: 0 3rem;
}
@media screen and (max-width: 640px) {
  img.img.img-responsive {
    margin: auto;
  }
}
</style>